

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { User } from "../../models/user.model";


interface AppState {
    sidebarOpen: boolean;
    loading: boolean;
    error: string;
    user: User | undefined
}

const u = localStorage.getItem("user")

const initialState: AppState = {
    sidebarOpen: false,
    loading: false,
    error: "",
    user: u ? JSON.parse(u) : undefined
};

const appStateReducer = createSlice({
    name: "appState",
    initialState,
    reducers: {
        toggleSidebar: (state, action: PayloadAction<boolean>) => {
            state.sidebarOpen = action.payload;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setError: (state, action: PayloadAction<string>) => {
            state.error = action.payload;
        },
        setUserState: (state, action: PayloadAction<User>) => {
            state.user = action.payload;
            localStorage.setItem("user", JSON.stringify(state.user))
        },
        clearUserState: (state) => {
            state.user = undefined;
            localStorage.clear()
        }
    }
});

export const { toggleSidebar, setUserState, clearUserState } = appStateReducer.actions;
export const selectAppState = (state: RootState) => state.appState;
export const selectUserState = (state: RootState) => state.appState.user

export default appStateReducer.reducer;

