/* eslint-disable react-hooks/exhaustive-deps */
import { ethers } from "ethers";
import { useEffect, useState } from "react";
import useConnector from "./useConnector";
import config from "../config";
import gas_station from "../contracts/gas_station.json";
import axios from "axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { backend_url } from "../utils/be";
import { selectUserState } from "../redux/reducers/appStateReducer";

const useGasStation = () => {
  const { address } = useSelector(state => state.twinergy);
  const { provider } = useConnector();
  const [contract, setContract] = useState(null);
  const [meters, setMeters] = useState([]);
  const { location } = window

  useEffect(() => {
    if (provider) {
      const signer = provider.getSigner();
      setContract(new ethers.Contract(config.gas_station, gas_station, signer));
    }
  }, [provider, location.pathname]);

  useEffect(() => {

    const user_id = localStorage.getItem("userID")


    if (address) {
      axios
        .get(`${backend_url}/meter/get_meters/${user_id}`, {
          headers: { "ngrok-skip-browser-warning": true },
        })
        .then(res => {

          setMeters([...res.data]);

        });
    }
  }, [address, location.pathname]);

  const fundMeters = async (amount, _meters) => {
    try {
      const options = { value: ethers.utils.parseEther(String(amount)) };

      let txn = await contract.fundMeters(_meters, options);
      await txn.wait();
      toast.success("Transaction Successful !");
      window.location.reload();
    } catch (error) {
      console.error(error);
      toast.error("Transaction Unsuccessful !");
    }
  };

  return { meters, fundMeters };
};

export default useGasStation;
