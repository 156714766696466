import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios, { AxiosHeaders } from "axios";
import { toast } from "react-toastify";
import {
  Form,
  Button,
  FormCheck,
  Row,
  Col,
  Card,
} from "react-bootstrap";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { backend_url } from "../utils/be";
import { IonCheckbox, IonSpinner, useIonToast } from "@ionic/react";
import footerImage from "../asserts/images/footer_flag.svg";
import "./../styles/login.css";
import jwt from "jwt-decode";
import { endpoint } from "../utils/endpoint";

export default function LoginPage() {
  let navigate = useNavigate();

  const [email, setemail] = useState("");
  const [pass, setpass] = useState("");
  const [showPassword, setShowPassword] = useState(false)
  const [loading, setLoading] = useState(false)

  function test(e: any) {
    e.preventDefault();
    const keycloakConfig = {
      url: ' https://auth.tec.etra-id.com/auth',
      realm: 'TwinERGY',
      clientId: 'transactiveenergymodule'
    };

    // Function to fetch the Keycloak access token
    async function getAccessToken() {
      const response = await axios.post(
        `${keycloakConfig.url}/realms/${keycloakConfig.realm}/protocol/openid-connect/token`,
        new URLSearchParams({
          grant_type: 'client_credentials',
          client_id: keycloakConfig.clientId,
          client_secret: '7c9286a7-7fbb-4358-a92c-b7a8f53f5e81'
        }),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      );

      return response.data.access_token;
    }

    try {
      console.log(getAccessToken())
    } catch (err) {
      console.log(err)
    }
  }

  const signin = (e: any) => {
    e.preventDefault();

    let myHeaders = new Headers() as any;
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append(
      "access_token",
      "eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJYMFA0QkdzZTJKdUVsTFJBcVM5MTM3c1MycVhOeEZYZS14Y3lHM3B0QXpVIn0.eyJleHAiOjE2ODU1MjYzNzIsImlhdCI6MTY4NTUxOTE3MiwianRpIjoiOTIwNTllNzktN2Y3NC00ODZkLTlkNWUtNTYwMGZkMTJmMTEwIiwiaXNzIjoiaHR0cHM6Ly9hdXRoLnRlYy5ldHJhLWlkLmNvbS9hdXRoL3JlYWxtcy9Ud2luRVJHWSIsImF1ZCI6WyJDaXRpemVuc1BsYXRmb3JtIiwiYWNjb3VudCJdLCJzdWIiOiJhYmU1ZmRhNi04YTNjLTQ3YzItOWQzYy0zNzk2NTVlMzU3MTQiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJ0cmFuc2FjdGl2ZWVuZXJneW1vZHVsZSIsInNlc3Npb"
    );
    myHeaders.append("token_type", "Bearer");
    myHeaders.append("scope", "email profile");
    myHeaders.append("Content-Allow-Origin", "*");

    let urlencoded = new URLSearchParams();
    urlencoded.append("client_id", "transactiveenergymodule");
    urlencoded.append("username", "test_bristol");
    urlencoded.append("password", "test_bristol");
    urlencoded.append("grant_type", "password");
    urlencoded.append("client_secret", "7c9286a7-7fbb-4358-a92c-b7a8f53f5e81");

    axios
      .post(
        "https://auth.tec.etra-id.com/auth/realms/TwinERGY/protocol/openid-connect/token",
        urlencoded,
        {
          headers: myHeaders,
          // mode: "no-cors" as any,
        }
      )
      .then(async (response) => {

        console.log(response.data.access_token.substring(0, 100) + "...");
        // navigator.clipboard.writeText(response.data.access_token);
        const userdata = jwt(response.data.access_token) as any
        const id = await verifyData(userdata);

        if (!id) throw new Error("Missen id ")


        await getDwelling(response.data.access_token, userdata.dwellingId, id); // ISCAN

        localStorage.setItem("user_logged_in", "true");
        localStorage.setItem(
          "user",
          JSON.stringify(userdata)
        );
        localStorage.setItem(
          "dwellingID",
          userdata.dwellingId
        );


        navigate("/");
        window.location.reload();
      })
      .catch((error) => {
        console.error(error);
      });
  };


  const verifyData = async (userdata: any) => {
    const url = backend_url + "/user/register";
    const body = {
      name: userdata.name,
      email: userdata.email,
      country: "temp_country",
      wallet: null,
      familyName: userdata.family_name,
      language: "temp_lang",
      phone: "+1234567890000",
      pilotID: userdata.pilotId,
      clientID: userdata.clientId,
      dwellingId: userdata.dwellingId,
    };



    const resp = await axios.post(url, body);
    if (resp.data.success) {
      console.log(resp.data.data.id)
      localStorage.setItem("userID", resp.data.data.id);

      await axios.put(
        `${backend_url}/user/${resp.data.data.id}`,
        { dwelling_id: userdata.dwellingId }
      );

      return resp.data.data.id
    } else {
      console.error("our backend . . . ." + resp);
      // throw new Error("unabel to post LINE 155")
    }
  };

  const getDwelling = async (received_token: string, dwel_ID: string, userId: string) => {

    // const backend_url="http://localhost:5002/api/v1"
    try {
      const res = await axios.get(`${backend_url}/user/userData/keycloak/${dwel_ID}/${userId}`, {
        headers: {
          Authorization: `Bearer ${received_token}`,
        },
      });
      console.log("success iscan")
      console.log(res)

    } catch (error) {
      console.error("error_geting_dwelling_from_ISCAN", error);
      
    }
  };

  return (
    <React.Fragment>
      <Row className="col-container">
        <Col className="left-col " sm="12" md="6">
          <div>
            <h1 className="">Sign In</h1>
            <p className="text-gray-500 font-light">Enter your username and password to sign in!</p>
            <form onSubmit={(e) => signin(e)} className="text-left ">
              <div className="text-black font-light mb-3">
                <label className="text-[14px]">Username/Email*</label>
                <input
                  value={email}
                  onChange={(e) => setemail(e.target.value)}
                  placeholder="mail@simmple.com" className={styles["login-input"]} type="text" />
              </div>
              <div className="text-black font-light mb-3">
                <label className="text-[14px]">Password*</label>
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    value={pass}
                    onChange={(e) => setpass(e.target.value)}
                    placeholder="Min. 8 characters" className={styles["login-input"]}
                  />
                  <span onClick={() => setShowPassword(!showPassword)} className="absolute right-3 text-gray-600 top-1/3">
                    {!showPassword ? <AiOutlineEye /> :
                      <AiOutlineEyeInvisible />}
                  </span>
                </div>

              </div>
              <div className="grid grid-cols-2 font-light text-sm ">
                <div className="items-center flex">
                  <IonCheckbox color={"warning"} style={{ "--border-color": "var(--ion-color-warning)" }} />
                  <span className="ml-1">Keep me logged in</span>
                </div>
              </div>
              <div className="mt-10 ">
                <button type="submit" className="p-3 w-full bg-[#7EDD62] rounded-xl text-white text-sm">
                  {!loading ? "Sign In" : <IonSpinner color={"dark"} />}
                </button>
              </div>
              {/* <div className="mt-10 font-light text-sm">
                <span>Create an Account?</span>
                <Link className="text-[#7EDD62] ml-1 hover:text-[#7EDD62] font-normal" to="https://twinergy_citizens.tec.etra-id.com/register">Create an Account</Link>
              </div> */}

            </form>
          </div>
        </Col>
        <Col className="right-col relative">
          <Card
            style={{ width: "18rem", border: 0, boxShadow: "none" }}
            className="right-card"
          >
            <Card.Img
              variant="top"
              src={require("./../asserts/images/logo.svg").default}
            />
            <Card.Text className="right-text">
              Transactive Energy Module
            </Card.Text>
          </Card>
          <div className="absolute bottom-0 text-left w-full px-4 lg:px-10 py-4 text-[#8F9BBA] items-center text-xs flex">
            <img src={footerImage} className="w-14 rounded-sm" />
            <div className="mx-2">
              Twinergy has received funding from the European Union's Horizon 2020 Research and Innovation Programme under Grant Agreement No. 957736
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
}

const styles = {
  "login-input": "inline-block w-full placeholder:font-light placeholder:text-gray-600 border-gray-600 border-[0.5px] rounded-xl p-2 pl-3 outline-yellow-500"
}
