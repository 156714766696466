import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

export default function AlertModal({ title, message, show, setShow }) {
  const handleClose = () => {
    setShow(false);
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <button className="bg-primaryGreen w-100 rounded-md text-white p-2" onClick={handleClose}>
          OK
        </button>
      </Modal.Footer>
    </Modal>
  );
}
