/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { ethers } from "ethers";
import { useDispatch, useSelector } from "react-redux";
import { updateAddress } from "../redux/reducers/twinergyReducer";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { backend_url } from "../utils/be";
import { selectUserState } from "../redux/reducers/appStateReducer";

function useConnector() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [provider, setProvider] = useState(null);
  const user = useSelector(selectUserState);
  const { location } = window

  const meters = [
    "Main meter",
    "PV meter",
    "Battery Main meter",
    "Battery Transact meter",
  ];
  const _meters = [
    "smart_meter_main",
    "smart_meter_pv1",
    "smart_meter_battery",
    "smart_meter_battery_transact",
  ];

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on("chainChanged", () => {
        window.location.replace("/");
      });
      window.ethereum.on("accountsChanged", () => {
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      });
    } else {
      // window.location.replace("/b-error");
      if (
        window.confirm("Please click OK to install metamask on your browser.")
      ) {
        window.location.href = "https://metamask.io/download";
      }
    }
  });

  useEffect(() => {
    // check Connection
    checkConnection();
    // Set Provider
    setProvider(new ethers.providers.Web3Provider(window?.ethereum));
  }, [location.pathname]);

  useEffect(() => {
    // Check Network
    checkNetwork();
  }, [provider, location.pathname]);

  function checkConnection() {
    window?.ethereum
      .request({ method: "eth_accounts" })
      .then(handleAccountsChanged)
      .catch(console.error);
  }

  function handleAccountsChanged(accounts) {
    if (accounts.length === 0 && window.location.pathname !== "/") {
      dispatch(updateAddress(""));
    } else {
      dispatch(updateAddress(accounts[0]));
      checkNetwork();
    }
  }

  const checkNetwork = async () => {
    try {
      const network = await provider.getNetwork();
      if (network.chainId !== 4002) return navigate("/error");
    } catch (error) { }
  };

  const connectWallet = async () => {
    provider
      .send("eth_requestAccounts")
      .then(async result => {
        localStorage.setItem("prosumer", result[0]);
        dispatch(updateAddress(result[0]));
        // send meters data
        let dwellingID = user.dwelling_id;
        let user_id = localStorage.getItem("userID");

        for (let i = 0; i < 4; i++) {
          const m = localStorage.getItem("meters")
          let meterSN = m ?JSON.parse(localStorage.getItem("meters"))[
            _meters[i]
          ]:"NO-SN";
          const body = {
            serialNumber: meterSN,
            dwellingId: dwellingID,
            dwellingIdentifier: meters[i],
            userId: user_id,
          };

          try {
            const meterSend = await axios.post(
              `${backend_url}/meter/post_dwelling_id`,
              body
            );
            console.log(meterSend, "106 LINE");
            if (!meterSend.data.success) {
              alert("meter_backend_save_opperation", meterSend.data.message);
            }
          } catch (error) {
            console.error(error);
          }
        }

        console.log({ result, user_id });
        const response = await axios.put(`${backend_url}/user/${user_id}`, {
          wallet: result[0],
        });

        console.log("wallet updated ", response)
      })
      .catch(error => {
        console.error(error);
      });
  };

  const switchNetwork = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: 4002 }],
      });
    } catch {
      window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: "0xfa2",
            rpcUrls: ["https://rpc.testnet.fantom.network/"],
            chainName: "Fantom testnet",
            nativeCurrency: {
              name: "FTM",
              symbol: "FTM",
              decimals: 18,
            },
            blockExplorerUrls: ["https://testnet.ftmscan.com"],
          },
        ],
      });
    }
  };
  return {
    connectWallet,
    switchNetwork,
    provider,
  };
}
export default useConnector;
