import React, { useEffect, useState } from "react";
import { Button, Image, Row, Dropdown } from "react-bootstrap";
import "./nav.css";
import useConnector from "../../hooks/useConnector";
import { useDispatch, useSelector } from "react-redux";
import useData from "../../hooks/useData";
import styles from "./nav.module.css";
import millify from "millify";
import Bristol from "./../../asserts/images/Bristol.svg";
import Benet from "./../../asserts/images/Benet.svg";
import Hagedorn from "./../../asserts/images/Flags.svg";
import { useNavigate } from "react-router-dom";
import { BsGear } from "react-icons/bs"
import { IoLogOut, IoLogOutOutline, IoSettingsOutline } from "react-icons/io5";
import { RootState } from "../../redux/store";
import SideBarDropDown from "../SideBar/SideBarDropDown";
import WalletConnect from "../SideBar/WalletConnect";
import { AiOutlineMenu } from "react-icons/ai";
import { selectAppState, selectUserState, toggleSidebar } from "../../redux/reducers/appStateReducer";

export default function NavBar() {
  let navigate = useNavigate();
  const { connectWallet } = useConnector();
  const { address } = useSelector((state: RootState) => state.twinergy);
  const data = useData();
  const { TRGYBalance } = useSelector((state: RootState) => state.twinergy);
  const [username, setUsername] = useState("");
  const [pilotsite, setPilotsite] = useState("");
  const [image, setImage] = useState(Hagedorn);
  const dispatch = useDispatch()
  const userState = useSelector(selectUserState)
  const { sidebarOpen } = useSelector(selectAppState)

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const user = userState;
    if (!user) return;

    setUsername(user.name);
    user.pilotId && setPilotsite(user.pilotId);
    if (user.pilotId === "bristol") {
      setImage(Bristol);
    } else if (user.pilotId === "benetutti") {
      setImage(Benet);
    } else if (user.pilotId === "hagedorn") {
      setImage(Hagedorn);
    }
  };


  return (
    <div className="flex sm:overflow-auto overflow-scroll justify-between px-8 lg:px-20 border-b sticky py-2 top-0 bg-white z-50 border-gray-400">

      <div className=" flex items-center ">
        <button className="md:hidden mr-6 text-green-500" onClick={() => dispatch(toggleSidebar(true))}>
          <AiOutlineMenu />
        </button>
        <Image src={image} />
        <span className="ttext">{pilotsite}</span>
      </div>
      <div className=" flex items-center ">
        <WalletConnect />

        <span>
          <SideBarDropDown />
        </span>

        <span className="user flex items-center capitalize">
          <button className="w-6 uppercase  h-6 text-sm rounded-full bg-blue-400 text-white">
            {username.trim()[0]}
          </button>
          <span className="name hidden sm:block">{username}</span>
        </span>

      </div>
    </div>
  );
}
