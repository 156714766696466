import React, { useState } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Container,
  CardBody,
  CardTitle,
  CardText,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

export default function SignupPage() {
  const [fname, setfname] = useState("");
  const [email, setemail] = useState("");
  const [pass, setpass] = useState("");
  const [city, setcity] = useState("");
  const [rid, setrid] = useState("");
  const [lemid, setlemid] = useState("");
  return (
    <Row>
      <Col className="form_holder container-fluid">
        <Container fluid>
          <Card className="Sign_Up_title" style={{ width: "100%", border: 0 }}>
            <CardBody>
              <CardTitle>Register</CardTitle>
              <CardText>
                We need you to hlp us with some basic information for your
                account creation. Here are our{" "}
                <a href="">Terms and conditions</a> Please read them carefully.
                We are GDRP compliant.
              </CardText>
            </CardBody>
          </Card>

          <Card className="login_form" style={{ width: "100%", border: 0 }}>
            <Form>
              <FormGroup>
                <Label for="email">Full Name*</Label>
                <Input
                  id="fname"
                  placeholder="John Smith"
                  type="text"
                  value={fname}
                  onChange={(e) => setfname(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label for="email">Username/Email *</Label>
                <Input
                  id="email"
                  placeholder="mail@Simmmple.com"
                  type="email"
                  value={email}
                  onChange={(e) => setemail(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label for="password">Password *</Label>
                <Input
                  id="password"
                  placeholder="Min. 8 characters"
                  type="password"
                  value={pass}
                  onChange={(e) => setpass(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label for="city">City*</Label>
                <Input
                  id="city"
                  placeholder="Hugendorn"
                  type="text"
                  value={city}
                  onChange={(e) => setcity(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label for="rid">Residence ID*</Label>
                <Input
                  id="rid"
                  placeholder="61e67h-1"
                  type="text"
                  value={rid}
                  onChange={(e) => setrid(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label for="lemiid">LEM ID*</Label>
                <Input
                  id="lemid"
                  placeholder="25984687"
                  type="text"
                  value={lemid}
                  onChange={(e) => setlemid(e.target.value)}
                />
              </FormGroup>
              <Row>
                <Col>
                  <FormGroup check>
                    <Input type="checkbox" />
                    <Label check>
                      By creating an account you agree to the{" "}
                      <a href="">terms of use </a> and our{" "}
                      <a href="">privacy policy</a>
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Button className="submit_btn" style={{ width: "100%" }}>
                  Sign In
                </Button>
                <Row>
                  Already have an account? <a href="/">Sign In</a>
                </Row>
              </Row>
              <p>
                Not registered yet? <a href="">Create an Account</a>
              </p>
            </Form>
          </Card>
        </Container>
      </Col>
      <Col className="dask_side" style={{ backgroundColor: "grey" }}></Col>
    </Row>
  );
}
