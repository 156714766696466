import React, { useState } from 'react'
import { IoClose } from 'react-icons/io5'
import { Button, Modal } from "react-bootstrap";
import styles from "../layout/nav.module.css";
import { HiOutlineLightningBolt } from 'react-icons/hi';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import useEGYVault from '../../hooks/useEGYVault';
import { toast } from 'react-toastify';
import { IonCardContent, IonSpinner } from '@ionic/react';
import { motion } from 'framer-motion';


const CreateTradeListing: React.FC<{ show: boolean, handleClose: () => void, }> = ({ show, handleClose }) => {
    const { WATTBalance } =
        useSelector((state: RootState) => state.twinergy);
    const { createListing } = useEGYVault()
    const [energyAmount, setEnergyAmount] = useState<any>()
    const [loading, setLoading] = useState<any>()
    const [listingStatus, setListingStatus] = useState("")

    const sell = async () => {
        setLoading(true)
        let result = await createListing(energyAmount, "Bristol", (status: string) => { setListingStatus(status) })
        if (result) {
            toast.success("Operation successfull !!")
            handleClose()
            window.location.reload()
        } else {
            toast.error("Operation failed")
        }
        setLoading(false)

    }
    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            className="modal"
            style={{ borderRadius: "10px" }}
        >
            <Modal.Header style={{ position: "relative" }}>
                <Modal.Title>Create Listing</Modal.Title>
                <button className='text-xl' onClick={handleClose}>
                    <IoClose onClick={handleClose} />
                </button>
            </Modal.Header>
            <Modal.Body>
                <div className="p-2">
                    <div className="border-b py-1">
                        kWh balance
                    </div>
                    <div className=' mb-2 border-[1px] rounded-md border-gray-700 flex space-x-2 text-md px-2 items-center'>
                        <HiOutlineLightningBolt className='text-primaryGreen text-xl' /><input type="text" value={WATTBalance} className=" w-full py-2 outline-none border-none" />
                    </div>
                    <div className="border-b py-1">
                        Volume of kWh you want to sell
                    </div>
                    <div className=' mb-2 border-[1px] rounded-md border-gray-700 flex space-x-2 text-md px-2 items-center'>
                        <HiOutlineLightningBolt className='text-primaryGreen text-xl' />
                        <input type="text" value={energyAmount} onChange={e => setEnergyAmount(e.target.value)} className="w-full py-2 outline-none border-none" />
                    </div>
                    <motion.div
                        animate={!!listingStatus ? { opacity: [1, 0, 1], scale: [1, 0.6, 1.1, 1, 1.1, 1], originX: "50%", transition: { duration: 3 } } : { opacity: 0, scale: 0 }}
                        className="py-2 px-2 text-yellow-500">
                        {listingStatus}<IonSpinner color="warning" className='ml-1' />
                    </motion.div>
                </div>
            </Modal.Body>
            <Modal.Footer style={{ textAlign: "center", display: "flex", justifyContent: "space-between" }}>
                <Button
                    onClick={handleClose}
                    className={styles.sbtn}
                    style={{
                        backgroundColor: "#7EDD62",
                        borderRadius: "10px",
                        border: "none",
                        width: "45%",
                        display: "inline"
                    }}

                >Cancel
                </Button>
                <Button
                    onClick={sell}
                    className={styles.sbtn}
                    style={{
                        backgroundColor: "#7EDD62",
                        borderRadius: "10px",
                        border: "none",
                        width: "45%",
                        display: "inline"
                    }}

                >
                    {
                        loading ? "Loading .." : "Confirm"
                    }
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default CreateTradeListing