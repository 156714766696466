import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";
import styles from "./../styles/home.module.css";
import { backend_url } from "../utils/be";
import axios from "axios";
import { useSelector } from "react-redux";
import { selectUserState } from "../redux/reducers/appStateReducer";
import { IonFab, IonFabButton, IonSpinner } from "@ionic/react";
import { IoAdd, IoChevronBack, IoChevronForward } from "react-icons/io5";
import { motion, AnimatePresence } from "framer-motion"

interface DataPoint {
  name: string;
  Projected_price: number;
  Curent_price: number;
}


interface LemPriceData {
  id: number;
  timestamp: string;
  hour_index: string;
  bristol: string;
  hagedorn: string;
  benetutti: string;
}

const HomePage: React.FC = () => {
  const userInfo = useSelector(selectUserState)
  const [data, setData] = useState<LemPriceData[]>([]);
  const [loading, setLoading] = useState(false)
  const [filterValue, setFilterValue] = useState("day")
  const city = +(userInfo?.pilotId || 0) == 1 ? "bristol" : +(userInfo?.pilotId || 0) == 2 ? "hagedorn" : "benetutti"
  const graphRef = useRef<HTMLElement>(null)
  const [scrolledEnd, setScrolledEnd] = useState(false)

  useEffect(() => {
    getLemPriceFilter(filterValue);
  }, [filterValue])

  function getLemPriceFilter(filter: string) {
    const backend_url = `https://lem.dev.twinergy.transactiveenergymodule.com/api/v1/lem/lem_price/filter/${filter}`
    const newLocal = `${backend_url}`;
    setLoading(true);

    if (filter === "day") {
      getLemPriceToday()
      return
    }

    axios.get(newLocal)
      .then(function (response) {
        if (response.data.success) {

          console.log(response.data.data);
          const prices = (response.data.data) as LemPriceData[]
          let result = prices.reverse().map((p) => ({ ...p, dayHour: p.timestamp.split("/")[0] + "th/" + p.hour_index + " hr" }))
          if (filter == "month") {
            result = result.map((p, i) => {
              // if (i % 23 < 4) {
              //   return { ...p, dayHour: `(${p.timestamp.split("/")[0]} - ${+p.timestamp.split("/")[1]})` }
              // }
              return { ...p, dayHour: `${convertTimeAMPM(+p.hour_index)}`, otherIndex: `${p.timestamp.split("/")[0]}/${+p.timestamp.split("/")[1]}` }
            })
          }
          else if (filter == "week") {
            result = result.map((p, i) => {
              // if (i % 23 < 4) {
              //   return { ...p, dayHour: `(${p.timestamp.split("/")[0]} - ${+p.timestamp.split("/")[1]}) ${convertTimeAMPM(+p.hour_index)}` }
              // }
              return { ...p, dayHour: `${convertTimeAMPM(+p.hour_index)}`, otherIndex: `${p.timestamp.split("/")[0]}/${+p.timestamp.split("/")[1]}` }

            })
          }
          setData(result)
          console.log(result)
        } else {
          console.log("error")
        }
        setLoading(false)

      }
      )
      .catch(function (error) {
        console.log(error);
        setLoading(false)

      }
      )
  }

  function getLemPriceToday() {
    const backend_url = "https://lem.dev.twinergy.transactiveenergymodule.com/api/v1/lem"
    const newLocal = `${backend_url}/all_lem_price`;
    setLoading(true);


    setLoading(true);

    axios.get(newLocal)
      .then(function (response) {
        if (response.data.success) {
          const prices = (response.data.data) as LemPriceData[]

          setData(prices.filter((price, index) => {
            //if day not today
            if (index >= prices.length - 24) return true;
            return false;
          }).map((p) => ({ ...p, dayHour: convertTimeAMPM(+p.hour_index) }))
            .reverse());
          // console.log(response.data.data)
        } else {
          console.log("error")
        }
        setLoading(false)

      }
      )
      .catch(function (error) {
        console.log(error);
        setLoading(false)

      }
      )

  }

  return (
    <Row className="pl-3 ml-3">
      <Row className={`${styles.hcont}`}>
        <Row className={styles.Grow}>
          <Col md={12} lg={2}>
            <div>
              <select className={"mb-2"} onChange={(e) => { setFilterValue(e.target.value) }}>
                <option value={"day"}>Today</option>
                <option value={"week"}>Last week</option>
                <option value={"month"}>Last month</option>
              </select>
            </div>
            <div className={styles.kcont}>
              {/* <div className={styles.ttext}>
                <span className={styles.nmonth}></span>
                This month
              </div> */}
              <div className={styles.withprice}>
                <div className={styles.price}>€{data[data.length - 1]?.bristol ?? 0}</div>
                <div className={styles.unprice}>
                  LEM Price
                  {/* <span className={styles.uprice}>
                    <svg
                      width="10"
                      height="5"
                      viewBox="0 0 10 5"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className={styles.upicon}
                    >
                      <path
                        d="M0.869629 4.35122L5.06217 0.612061L9.25472 4.35122H0.869629Z"
                        fill="#BCCCCC"
                      />
                    </svg>
                    +2.45%
                  </span> */}
                </div>
              </div>
              <div className={styles.key}>
                <div className={styles.kcurrent}>
                  <motion.span initial={{ x: -100 }} animate={{ x: [-100, 10, 0], transition: { delay: 1 } }} className={styles.kccolor}></motion.span>
                  <motion.span initial={{ fontWeight: "normal", color: "auto", x: 0 }} animate={{ fontWeight: "bold", color: "#58b4ff", x: [0, 4, 2], transition: { delay: 1.3 } }}> LEM price / current</motion.span>
                </div>
              </div>
            </div>
            {scrolledEnd && <IonFab className="" onClick={() => { graphRef.current?.scroll({ left: 0, behavior: "smooth" }); setScrolledEnd(false) }} vertical="center" horizontal="end">
              <IonFabButton mode="ios" color="light" size="small">
                <IoChevronBack className="text-xl" />
              </IonFabButton>
            </IonFab>}
          </Col>
          <Col
            ref={graphRef as any} xs={12} lg={10} style={{ overflow: "scroll", position: "relative" }}>
            {loading && <IonSpinner className="absolute top-6" />}
            <AreaChart
              width={window.innerWidth - window.innerWidth / 100 * 25}
              height={window.innerHeight / 2.3}
              data={data}
              style={{ scrollbarWidth: "thin" }}
              className="text-sm "

              margin={{
                top: 10,
                right: 30,
                left: 0,
                bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="2 2" />
              <XAxis xAxisId="0" dataKey="dayHour" />
              {filterValue != "day" && <XAxis xAxisId="1" dataKey="otherIndex" allowDuplicatedCategory={false} />}
              <YAxis dataKey={"bristol"} domain={[0, 0.6]} />
              <Tooltip />
              <Area
                type="monotone"
                dataKey={"bristol"}
                stackId="1"
                stroke="#58b4ff"
                fill="#58b4ff"
              />
            </AreaChart>
            {!scrolledEnd && <IonFab onClick={() => { graphRef.current?.scroll({ left: 1000, behavior: "smooth" }); setScrolledEnd(true) }} vertical="center" horizontal="end">
              <IonFabButton className="" mode="ios" color="light" size="small">
                <IoChevronForward className="text-xl" />
              </IonFabButton>
            </IonFab>}


          </Col>

        </Row>
      </Row>
    </Row>
  );
};

export default HomePage;

export const convertTimeAMPM = (time: number) => {
  if (time < 12) {
    return `${time} am`;
  }
  if (time === 12) {
    return `${time} pm`;
  }
  return `${time - 12} pm`;
}
