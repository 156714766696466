import { IoGiftOutline } from "react-icons/io5";
import { FiBarChart2 } from 'react-icons/fi'

export const menuItem = [
    {
        path: "/",
        name: "Home",
        icon: (
            <svg
                className="svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M20.5125 9.72208L13.0125 2.90646C12.736 2.65348 12.3748 2.51318 12 2.51318C11.6252 2.51318 11.264 2.65348 10.9875 2.90646L3.4875 9.72208C3.33394 9.86257 3.2113 10.0335 3.12737 10.2239C3.04344 10.4144 3.00007 10.6202 3 10.8283V19.4627C2.9939 19.8409 3.12741 20.208 3.375 20.494C3.51552 20.6537 3.68862 20.7816 3.88267 20.8689C4.07672 20.9562 4.28721 21.001 4.5 21.0002H9C9.19891 21.0002 9.38968 20.9212 9.53033 20.7805C9.67098 20.6399 9.75 20.4491 9.75 20.2502V15.7502C9.75 15.5513 9.82902 15.3605 9.96967 15.2199C10.1103 15.0792 10.3011 15.0002 10.5 15.0002H13.5C13.6989 15.0002 13.8897 15.0792 14.0303 15.2199C14.171 15.3605 14.25 15.5513 14.25 15.7502V20.2502C14.25 20.4491 14.329 20.6399 14.4697 20.7805C14.6103 20.9212 14.8011 21.0002 15 21.0002H19.5C19.7488 21.0024 19.994 20.9411 20.2125 20.8221C20.4502 20.6926 20.6488 20.5016 20.7873 20.269C20.9259 20.0365 20.9993 19.7709 21 19.5002V10.8283C20.9999 10.6202 20.9566 10.4144 20.8726 10.2239C20.7887 10.0335 20.6661 9.86257 20.5125 9.72208Z"
                    fill="white"
                />
            </svg>
        ),
    },
    {
        path: "/wallet",
        name: "Wallet",
        icon: (
            <svg
                className="svg"
                width="18"
                height="17"
                viewBox="0 0 18 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.7698 0C15.654 0 18 2.34604 18 5.23107V11.4904C18 14.3745 15.654 16.7214 12.7698 16.7214H5.23107C2.34692 16.7214 0 14.3745 0 11.4904V5.23107C0 2.34604 2.34692 0 5.23107 0H12.7698ZM12.7698 1.3082H5.23107C3.06817 1.3082 1.3082 3.06817 1.3082 5.23107V11.4904C1.3082 13.6533 3.06817 15.4132 5.23107 15.4132H12.7698C14.9327 15.4132 16.6918 13.6533 16.6918 11.4904V11.2462L13.8156 11.2467C12.1611 11.2467 10.8146 9.901 10.8137 8.24743C10.8137 6.59212 12.1603 5.24554 13.8156 5.24467L16.6918 5.24415V5.23107C16.6918 3.06817 14.9327 1.3082 12.7698 1.3082ZM16.6918 6.55235L13.8156 6.55288C12.8815 6.55375 12.1219 7.31338 12.1219 8.24656C12.1219 9.17887 12.8824 9.9385 13.8156 9.9385L16.6918 9.93798V6.55235ZM14.2147 7.53839C14.5757 7.53839 14.8688 7.83143 14.8688 8.19249C14.8688 8.55355 14.5757 8.84659 14.2147 8.84659H13.9426C13.5815 8.84659 13.2885 8.55355 13.2885 8.19249C13.2885 7.83143 13.5815 7.53839 13.9426 7.53839H14.2147ZM9.31876 3.95784C9.67983 3.95784 9.97287 4.25087 9.97287 4.61194C9.97287 4.973 9.67983 5.26604 9.31876 5.26604H4.61011C4.24904 5.26604 3.95601 4.973 3.95601 4.61194C3.95601 4.25087 4.24904 3.95784 4.61011 3.95784H9.31876Z"
                    fill="white"
                />
            </svg>
        ),
    },
    {
        path: "/readings",
        name: "Readings",
        icon: (
            <svg
                className="svg"
                width="18"
                height="17"
                viewBox="0 0 18 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M16.8333 8.125H13.6667L11.2917 15.25L6.54167 1L4.16667 8.125H1"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        ),
    },
    {
        path: "/meters",
        name: "Meters",
        icon: (
            <svg
                className="svg"
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M9.16667 20.1668H12.8333C17.4167 20.1668 19.25 18.3335 19.25 13.7502V8.25016C19.25 3.66683 17.4167 1.8335 12.8333 1.8335H9.16667C4.58333 1.8335 2.75 3.66683 2.75 8.25016V13.7502C2.75 18.3335 4.58333 20.1668 9.16667 20.1668Z"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M15.8125 7.59938C14.4863 6.42123 12.774 5.77051 11 5.77051C9.22605 5.77051 7.51373 6.42123 6.1875 7.59938L8.18583 10.8077C8.96049 10.117 9.96212 9.7353 11 9.7353C12.0379 9.7353 13.0395 10.117 13.8142 10.8077L15.8125 7.59938Z"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        ),
    },
    {
        path: "/account",
        name: "Account",
        icon: (
            <svg
                className="svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M20 21V19C20 17.9391 19.5786 16.9217 18.8284 16.1716C18.0783 15.4214 17.0609 15 16 15H8C6.93913 15 5.92172 15.4214 5.17157 16.1716C4.42143 16.9217 4 17.9391 4 19V21"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        ),
    },
    {
        path: "/der-rewards",
        name: "DER Rewards",
        icon: <IoGiftOutline />
    },
    {
        path: "/faucet",
        name: "Faucet",
        icon: (
            <svg
                className="svg"
                width="18"
                height="19"
                viewBox="0 0 18 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g clip-path="url(#clip0_456_400)">
                    <path
                        d="M13.2268 7.01911H11.9978C11.3384 7.01911 10.7061 6.75685 10.2414 6.29034L9.69289 5.73991C9.2323 5.27772 8.97384 4.65262 8.97384 4.00098V2.96506H11.315C11.6883 3.61114 12.4154 4.02844 13.2336 3.9493C14.1534 3.86029 14.9073 3.12057 15.0088 2.20498C15.1413 1.01077 14.2068 0 13.0355 0C12.3061 0 11.6703 0.393233 11.3251 0.977452H4.66312C4.2858 0.339393 3.56211 -0.0708098 2.7485 0.0101818C1.8312 0.101509 1.08043 0.840307 0.979055 1.75374C0.84642 2.94809 1.78106 3.95902 2.95249 3.95902C3.68887 3.95902 4.33007 3.55854 4.67303 2.96522H6.95336V4.00114C6.95336 4.65262 6.6949 5.27772 6.23432 5.74006L5.68582 6.2905C5.22106 6.75686 4.58884 7.01911 3.92937 7.01911H0V11.0207H13.2241C13.6446 11.0207 13.9853 11.3606 13.9853 11.7796V13.0085H17.9998V11.7769C17.9998 9.14927 15.8628 7.01911 13.2267 7.01911H13.2268Z"
                        fill="white"
                    />
                    <path
                        d="M17.8856 17.1299C17.8856 18.1628 17.0457 19 16.0095 19C14.9734 19 14.1335 18.1628 14.1335 17.1299C14.1335 16.0971 16.0095 14.1855 16.0095 14.1855C16.0095 14.1855 17.8856 16.0971 17.8856 17.1299Z"
                        fill="white"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_456_400">
                        <rect width="18" height="19" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        ),
    },
    {
        path: "/trade",
        name: "Trade",
        icon: (
            <FiBarChart2 />
        ),
    },
];