import React, { useRef, useState } from "react";

import { Link, NavLink } from "react-router-dom";
import "./sidebar.css";
import { Image } from "react-bootstrap";
import Footer from "./Footer";
import styles from "./sidebar.module.css";
import { useLocation } from "react-router-dom";
import { FaHeart, FaMitten } from "react-icons/fa";
import { BsMenuButton } from "react-icons/bs";
import { AiOutlineMenu } from "react-icons/ai";
import { menuItem } from "../../data/menu.data";
import { JsxElement } from "typescript";
import { useDispatch, useSelector } from "react-redux";
import { selectAppState, toggleSidebar } from "../../redux/reducers/appStateReducer";
import { IoClose } from "react-icons/io5";
import { motion } from "framer-motion"


function SideBar({ children }: { children: any }) {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(true);
  const { sidebarOpen } = useSelector(selectAppState)
  const toggle = () => setIsOpen(!isOpen);
  const dispatch = useDispatch();

  return (
    <div style={{ height: `${window.innerHeight}px` }} className={`side-bar-container relative ${sidebarOpen ? "sidebar-visible" : ""} `}>
      <div style={{ width: isOpen ? "300px" : "100px" }} className="sbsidebar">
        <div className="sbtop_section relative">
          <h1 style={{ display: isOpen ? "block" : "none" }} className="sblogo">
            <Image style={{ width: "170px", }} src={require("./../../asserts/images/logo.svg").default} />
          </h1>
          <div
            style={{ marginLeft: isOpen ? "56px" : "0px", }}
            className="sbbars absolute right-6"
          >
            <AiOutlineMenu onClick={toggle} />
          </div>
        </div>
        <div className="h-5"></div>
        {menuItem.map((item, index) => (
          <LinkItem isOpen={isOpen} key={index} dispatch={dispatch} item={item} />
        ))}
        {isOpen ? (
          <div className="sbfooter">
            <Footer />
          </div>
        ) : null}
      </div>
      <main>{children}</main>
      {sidebarOpen && <button onClick={() => dispatch(toggleSidebar(false))} className="absolute md:hidden top-10 right-14">
        <IoClose className="text-white text-4xl " />
      </button>}
    </div>
  );
}

export default SideBar;


function LinkItem({ item, dispatch, isOpen }: { item: any, dispatch: any, isOpen: boolean }) {
  const location = useLocation()
  // const hovered = item.path === location.pathname
  const [hovered, setHovered] = useState(false)

  return (
    <Link
      onMouseOver={() => { setHovered(true) }}
      onMouseLeave={() => { setHovered(false) }}
      to={item.path}
      onClick={() => dispatch(toggleSidebar(false))}
      className={
        "sblink font-light relative relative."
      }
    >
      <motion.div
        animate={{ rotate: hovered ? [10, 0] : 0, originX: "50%", }}
        className="sbicon">{item.icon}</motion.div>
      <div
        style={{ display: isOpen ? "block" : "none" }}
        className="sblink_text"
      >
        {item.name}
      </div>
      {item.path === location.pathname && <div className="w-1 h-full absolute right-0 mr-[1.5px] rounded bg-green-400"></div>}
    </Link>
  )
}
