/* eslint-disable import/no-anonymous-default-export */
export default {
  treasury: "0xA610e21c4a0d9116A3bB65E2F6E5D814cadE3346",
  TRGY: "0x1255b5f197b574Ba9783A08C4c0360DDC9bA4d0C",
  FFTM: "0xDd049be3A71a94B14e9Ee03534C33E6B0f8CF96a",
  WATT: "0x15a27768BD762F5F279975eC08F2C0FDA2c1BD8c",
  DRR: "0xff66C6fff0f7cAf9F003c1e2C6adc01433BE1313",
  faucet: "0xa68B2C666f12b7f19961B3375CD3Bea9DF5B9C97",
  trgy_vault: "0x80e565c8B44DB95c52e5AA1c837EA68C5179fF7f",
  energy_vault: "0x1204a7149814f7DF24d09195174407fd7400bC8a",
  meter_reading: "0x1C35b3150C68C45D78684e8A41F271ee9A401E5d",
  gas_station: "0x3f7D91cB5D745b3d7FFaB1BFf8fe352be53C217F",
};
