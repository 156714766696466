import axios from "axios";
import React, { useEffect, useState } from "react";
import { IoGiftOutline } from "react-icons/io5";
import { DRRReward, DRRToken } from "../types/rewards";
import ClaimRewardModal from "../components/modals/ConfirmClaim";
import ClaimRewardCard from "../components/rewards/ClaimRewardCard";
import { useDispatch, useSelector } from "react-redux";
import { selectUserState } from "../redux/reducers/appStateReducer";
import { IonSpinner } from "@ionic/react";
import { Link } from "react-router-dom";
import { RootState } from "../redux/store";
import useEGYVault from "../hooks/useEGYVault";
import { toast } from "react-toastify";
import { selectToken, setTokens } from "../redux/reducers/DERReducer";
import { backend_url } from "../utils/be";

export const der_baseurl = backend_url + "/derr"

const DERRewards = () => {
  const { DRRBalance } = useSelector((state: RootState) => state.twinergy);
  const { mintDRR } = useEGYVault();
  const [rewards, setRewards] = useState<DRRReward[]>([]);
  const tokens = useSelector(selectToken)
  const user = useSelector(selectUserState);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch()

  const headers: any = {
    "ngrok-skip-browser-warning": true,
  };
  useEffect(() => {
    setLoading(true);
    axios
      .get(`${der_baseurl}/get_rewards`, { headers })
      .then((res) => {
        if (res.data.success) {
          setRewards(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    getUnClaimedTokens();
  }, [user]);

  function getUnClaimedTokens() {
    if (user?.id) {
      console.log(`${der_baseurl}/get_derr/${user?.id}`);
      axios
        .get(`${der_baseurl}/get_derr/${user?.id}`, { headers })
        .then((res) => {
          if (res.data.success) {
            dispatch(setTokens(res.data.data));
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  async function claimDRRToken() {
    setLoading(true);
    if (user?.id) {
      let result = await mintDRR(Number(tokens?.unclaimed_tokens));
      if (result) {
        await axios
          .get(`${der_baseurl}/claim_derr/${user.id}`, { headers })
          .then((res) => {
            console.log(res.data);
            if (res.data.success) {
              console.log(res.data.data, "get DER CLAIMS");
              getUnClaimedTokens();
            }
            toast.success("DRR claimed successfully");
            setTimeout(() => {
              window.location.reload();
            }, 2000)
          })
          .catch((err) => {
            console.log(err);
            toast.error("Claim failed");

          });
      } else {
        toast.error("Claim failed");
      }
    }
    setLoading(false);
  }

  return (
    <div>
      <div className="p-4 grid-cols-2 lg:grid header">
        <div className="flex lg:gap-x-2">
          <Link to="/der-rewards/">
            <button className="text-primaryGreen text-2xl hover:bg-green-50 bg-opacity-20 px-4 rounded underline-offset-3 underline">
              DER Rewards
            </button>
          </Link>
          <Link to="/der-rewards/history">
            {" "}
            <button className="text-black text-2xl hover:bg-green-50 bg-opacity-20 px-4 rounded">
              History
            </button>
          </Link>
        </div>
        <div className="m-3 lg:px-3   lg:m-0 flex items-center lg:gap-3 justify-end">
          <span className="text-xs hidden xl:block text-gray-400">
            Demand response reward balance
          </span>
          <div>
            <button
              className="flex items-center rounded-full p-1 text-sm"
              style={{ border: "1px solid var(--ion-color-warning)" }}
            >
              <IoGiftOutline className="inline-block mr-1 text-primaryGreen" />
              {DRRBalance}
            </button>
          </div>
          <div>
            <button
              disabled={!tokens?.unclaimed_tokens}
              onClick={claimDRRToken}
              className={`ml-1 ${tokens?.unclaimed_tokens ? "bg-primaryGreen" : "bg-gray-200"
                } active:opacity-60 transition-all  text-sm text-white rounded-sm `}
            >
              {loading ? (
                <IonSpinner />
              ) : (
                <div className="p-1 px-2 flex items-center  ">
                  <span> Claim</span>{" "}
                  <span className="w-[3px] h-[3px] mx-1 rounded bg-white"></span>{" "}
                  <span>{tokens?.unclaimed_tokens}</span>
                </div>
              )}
            </button>
          </div>
        </div>
      </div>
      <div className="md:grid  lg:grid-cols-2 xl:grid-cols-3 p-4 gap-4">
        {rewards.map((reward) => {
          return <ClaimRewardCard tokens={tokens} reward={reward} />;
        })}
      </div>
    </div>
  );
};

export default DERRewards;
