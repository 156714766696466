import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface TwinergyState {
  address: string;
  WATTBalance: string;
  FTMBalance: string;
  DRRBalance: string;
  TRGYBalance: string;
}

const initialState: TwinergyState = {
  address: "",
  WATTBalance: "",
  FTMBalance: "",
  DRRBalance: "",
  TRGYBalance: "",
};

export const twinergyReducer = createSlice({
  name: "TwinERGY",
  initialState,
  reducers: {
    updateAddress: (state, action: PayloadAction<string>) => {
      state.address = action.payload;
    },

    updateWATTBal: (state, action: PayloadAction<string>) => {
      state.WATTBalance = action.payload;
    },

    updateFTMBal: (state, action: PayloadAction<string>) => {
      state.FTMBalance = action.payload;
    },

    updateDRRBal: (state, action: PayloadAction<string>) => {
      state.DRRBalance = action.payload;
    },

    updateTRGYBal: (state, action: PayloadAction<string>) => {
      state.TRGYBalance = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateAddress,
  updateWATTBal,
  updateFTMBal,
  updateDRRBal,
  updateTRGYBal,
} = twinergyReducer.actions;

export default twinergyReducer.reducer;