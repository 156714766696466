import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

export default function ConfirmModal({ title, message, show, handleClose }: { title: string, message: string, show: boolean, handleClose: (result?: boolean) => void }) {

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <div className="flex gap-x-5 w-full">
          <button className="bg-primaryGreen w-100 rounded-md text-white p-2" onClick={() => handleClose(true)}>
            Ok
          </button>
          <button className="bg-gray-600 w-100 rounded-md text-white p-2" onClick={() => handleClose()}>
            Cancel
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
