import React, { useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { FiFilter } from 'react-icons/fi'
import CreateTradeListing from '../modals/CreateTradeListing'
import useEGYVault from '../../hooks/useEGYVault'
import ListingsItem from './ListingsItem'
import { IonSpinner } from '@ionic/react'
import { AnimatePresence } from 'framer-motion'


const SellEnergyCard = () => {
    const [openModal, setOpenModal] = useState(false);
    const { listings, vaultLoading } = useEGYVault();
    const [waitingColor, setWaitingColor] = useState("warning");
    const [selectedFilter, setSelectedFilter] = useState("");

    useEffect(() => {
        let t: any;

        const updateWaitingColor = () => {
            setWaitingColor((prevColor) => (prevColor === "warning" ? "success" : "warning"));
        };

        const startTimer = () => {
            t = setTimeout(() => {
                updateWaitingColor();
                startTimer();
            }, 5000);
        };

        if (vaultLoading) {
            t = setTimeout(() => {
                updateWaitingColor();
                startTimer();
            }, 3000);
        }

        return () => clearTimeout(t);
    }, [vaultLoading]);

    function handleFilterSelection(filter: string) {
        setSelectedFilter(filter);
    }

    function applyFilter(listings: any[]) {
        switch (selectedFilter) {
            case "kwh_high_low":
                return listings.sort((a, b) => b.kwh - a.kwh);
            case "kwh_low_high":
                return listings.sort((a, b) => a.kwh - b.kwh);
            case "price_high_low":
                return listings.sort((a, b) => b.price - a.price);
            case "price_low_high":
                return listings.sort((a, b) => a.price - b.price);
            default:
                return listings;
        }
    }

    const filteredListings = applyFilter(listings);

    return (
        <div className="p-3 shadow rounded-lg">
            <div className="my-3 flex justify-between items-center font-medium text-xl">
                Sell
                <div className="flex items-center gap-x-2">
                    <button onClick={() => setOpenModal(true)} className="w-32 text-sm bg-primaryGreen p-[5px] text-white font-normal hover:bg-opacity-80 rounded-md">
                        Create Listing
                    </button>
                    <Dropdown style={{ background: "transparent" }}>
                        <Dropdown.Toggle style={{ display: "flex", alignItems: "center", background: "transparent", outline: "none", border: "none", color: "black" }}>
                            <FiFilter className="" />
                        </Dropdown.Toggle>

                        <Dropdown.Menu style={{ border: "1px solid var(--ion-color-success)", fontSize: "13px", color: "gray" }}>
                            <Dropdown.Item style={{ padding: "3px 10px" }} onClick={() => handleFilterSelection("kwh_high_low")}>KWH high - low</Dropdown.Item>
                            <Dropdown.Item style={{ padding: "3px 10px" }} onClick={() => handleFilterSelection("kwh_low_high")}>KWH low - high</Dropdown.Item>
                            <Dropdown.Item style={{ padding: "3px 10px" }} onClick={() => handleFilterSelection("price_high_low")}>Price high - low</Dropdown.Item>
                            <Dropdown.Item style={{ padding: "3px 10px" }} onClick={() => handleFilterSelection("price_low_high")}>Price low - high</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
            <div className="border-b pb-2">
                <span className="text-gray-500 text-sm">Your Active Listings</span>
            </div>
            <section className="mt-4 h-[60vh] overflow-auto relative">
                <div className="absolute top-[40%] left-1/2 text-center -translate-x-1/2 -translate-y-1/2">
                    <aside>{vaultLoading && <IonSpinner color={waitingColor} />}</aside>
                </div>
                {filteredListings.map((item: any, index: any) => (
                    <ListingsItem item={item} key={index} />
                ))}
            </section>
            <CreateTradeListing show={openModal} handleClose={() => setOpenModal(false)} />
        </div>
    );
};

export default SellEnergyCard