import React from "react";
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import SignupPage from "./pages/SignupPage";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.min.css";
import HomePage from "./pages/HomePage";
import SideBar from "./components/layout/SideBar";
import WalletPage from "./pages/WalletPage";
import FaucetPage from "./pages/FaucetPage";
import ReadingsPage from "./pages/ReadingsPage";
import AccountPage from "./pages/AccountPage";
import MetersPage from "./pages/MetersPage";
import NavBar from "./components/layout/NavBar";
import { ToastContainer } from "react-toastify";
import checkLogin from "./utils/checkLogin";
import ErrorPage from "./pages/ErrorPage";
import BrowserError from "./pages/BrowserError";
import { IonApp, setupIonicReact } from '@ionic/react';
import DERRewards from "./pages/DERRewards";
import DERRewardsHistory from "./pages/DERRewardHistory";
import TradePage from "./pages/TradePage";
import TradeTransactions from "./pages/TradeTransactions";

setupIonicReact();

export default function App() {
  let user_logged_in = localStorage.getItem("user_logged_in");
  const debugging = true
  if (user_logged_in) {
    checkLogin();
    return (
      <IonApp>
        <div className="h-full overflow-scroll">
          <Router>
            <SideBar >
            </SideBar>
            <NavBar />
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/wallet" element={<WalletPage />} />
              <Route path="/faucet" element={<FaucetPage />} />
              <Route path="/der-rewards" element={<DERRewards />} />
              <Route path="/der-rewards/history" element={<DERRewardsHistory />} />
              <Route path="/readings" element={<ReadingsPage />} />
              <Route path="/account" element={<AccountPage />} />
              <Route path="/meters" element={<MetersPage />} />
              <Route path="/error" element={<ErrorPage />} />
              <Route path="/trade" element={<TradePage />} />
              <Route path="/trade/transactions" element={<TradeTransactions />} />
            </Routes>
            <div className="bg-red-400">
              <ToastContainer />
            </div>
          </Router>
        </div>
      </IonApp>
    );
  }


  else {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<Navigate to="/signin" />} />
          <Route path="/get-started" element={<SignupPage />} />
          <Route path="/signin" element={<LoginPage />} />
          {/* <Route path="/b-error" element={<BrowserError />} /> */}
        </Routes>
        <ToastContainer />
      </Router>
    );
  }
}
