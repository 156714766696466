import React, { useState, useEffect } from "react";
import { Button, Card, Form, Row } from "react-bootstrap";
import styles from "./../styles/wallet.module.css";
import { toast } from "react-toastify";
import axios from "axios";
import { backend_url } from "../utils/be";
import { useDispatch, useSelector } from "react-redux";
import { clearUserState, selectUserState, setUserState } from "../redux/reducers/appStateReducer";

export default function AccountPage() {
  const [name, setName] = useState("");
  const [familyName, setFamilyName] = useState("");
  const [btn1_text, setbtn1_text] = useState("Edit");
  const [btn2_text, setbtn2_text] = useState("Edit");
  const [phone, setPhone] = useState("");
  const [id, setId] = useState("");
  const [address, setAddress] = useState("");
  const [dwelling, setDwelling] = useState("");
  const [language, setLanguage] = useState("");
  const [country, setCountry] = useState("");
  const user = useSelector(selectUserState)
  const dispatch = useDispatch()

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {

    if (user) {
      setName(user.name);
      setFamilyName(user.family_name);
      setId(user.iat);
      setDwelling(user.dwelling_id);
      setPhone(user.phone);
      setId(user.id_number);
      setAddress(user.postal_address);
      setLanguage(user.language);
      setCountry(user.country);
    }
  };
  const editPersonal = async (e: any) => {
    e.preventDefault();
    setbtn1_text("Loading");
    let user_id = localStorage.getItem("userID");
    const res = await axios.put(
      `${backend_url}/user/${user_id}`,
      { phone: phone, id_number: id, postal_address: address },
      {
        headers: {
          "ngrok-skip-browser-warning": true,
        },
      }
    );
    if (res.data.success) {
      setbtn1_text("Edited");
      dispatch(clearUserState())
      dispatch(setUserState(res.data.data))
      toast.success(res.data.message);
    } else {
      setbtn1_text("Edit");
      toast.error(res.data.message);
    }
  };
  const editProfile = async (e: any) => {
    e.preventDefault();
    setbtn2_text("Loading");
    let user_id = localStorage.getItem("userID");
    const res = await axios.put(
      `${backend_url}/user/${user_id}`,
      { language: language, country: country },
      {
        headers: {
          "ngrok-skip-browser-warning": true,
        },
      }
    );
    if (res.data.success) {
      setbtn2_text("Edited");
      dispatch(clearUserState())
      dispatch(setUserState(res.data.data))
      toast.success(res.data.message);
    } else {
      setbtn2_text("Edit");
      toast.error(res.data.message);
    }
  };

  const handleMouseEnter = (e: any) => {
    e.target.style.color = "#06152b";
  };
  const handleMouseLeave = (e: any) => {
    e.target.style.color = "white";
  };
  return (
    <div className="grid lg:grid-cols-2 gap-6 mt-10 " style={{ padding: 30 }}>


      <div className={"meter-card p-6 px-10"} style={{ position: "relative" }}>
        <div className={"font-semibold text-3xl mb-3"} >
          Personal data
        </div>
        <div className="mb-4 font-semibold">
          <label >Name*</label>
          <input value={name} type="text" placeholder="Elena" className="block border-b border-gray-500 outline-none w-full py-2 placeholder:font-light" />
        </div>
        <div className="mb-4 font-semibold">
          <label >Family Name*</label>
          <input value={familyName} type="text" placeholder="Stanley" className="block border-b border-gray-500 outline-none w-full py-2 placeholder:font-light" />
        </div>
        <div className="mb-4 font-semibold">
          <label >Phone</label>
          <input value={phone} type="text" placeholder="0044  6837 267584" className="block border-b border-gray-500 outline-none w-full py-2 placeholder:font-light" />
        </div>
        <div className="mb-4 font-semibold">
          <label >Identification number or passport</label>
          <input type="text" placeholder="PA0940562" className="block border-b border-gray-500 outline-none w-full py-2 placeholder:font-light" />
        </div>
        <div className="mb-4 font-semibold">
          <label >Postal Address</label>
          <input type="text" placeholder="63 Sandyhill Rd, Langford, LD2 0AR, United Kingdom   " className="block border-b border-gray-500 outline-none w-full py-2 placeholder:font-light" />
        </div>
        <div className="mb-4 font-semibold text-end">
          <button className="w-[100px] rounded-xl bg-[#7EDD62] text-white p-2">
            Edit
          </button>
        </div>

      </div>
      <div className={"meter-card p-6 px-10 relative"} style={{ position: "relative" }}>
        <div className={"font-semibold text-3xl mb-3"} >
          Profile Data
        </div>
        <div className="mb-4 font-semibold">
          <label >Dwelling*</label>
          <input value={dwelling} type="text" placeholder="Pilot 1" className="block border-b border-gray-500 outline-none w-full py-2 placeholder:font-light" />
        </div>
        <div className="mb-4 font-semibold">
          <label >Language*</label>
          <input value={''} type="text" placeholder="English" className="block border-b border-gray-500 outline-none w-full py-2 placeholder:font-light" />
        </div>
        <div className="mb-4 font-semibold">
          <label >Country</label>
          <input value={phone} type="text" placeholder="United Kingdoms" className="block border-b border-gray-500 outline-none w-full py-2 placeholder:font-light" />
        </div>

        <div className="mb-4 font-semibold text-end lg:absolute bottom-6  right-6">
          <button className="w-[100px] rounded-xl bg-[#7EDD62] text-white p-2">
            Edit
          </button>
        </div>
      </div>
    </div>
  );
}
