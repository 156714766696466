import React, { useEffect, useState } from 'react'
import { Dropdown, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import PurchaseEnergyCard from '../components/TradeEnergy/PurchaseEnergyCard'
import SellEnergyCard from '../components/TradeEnergy/SellEnergyCard'
import { FiFilter } from 'react-icons/fi'
import { HiOutlineLightningBolt } from 'react-icons/hi'
import { IoCopyOutline, IoSearchOutline, IoTrashOutline } from 'react-icons/io5'
import { GiCrystalShine } from 'react-icons/gi'
import { BsArchive, BsBoxArrowInDownLeft, BsCalendar4, BsGrid } from 'react-icons/bs'
import axios from 'axios'
import { backend_url } from '../utils/be'
import { useSelector } from 'react-redux'
import { selectUserState } from '../redux/reducers/appStateReducer'
import { useIonToast } from '@ionic/react'
import { ITradeTransaction } from '../types/trade'
import TransactionCard from '../components/TradeEnergy/TransactionCard'
import { toast } from 'react-toastify'

const TradeTransactions = () => {

    const user = useSelector(selectUserState)
    const [transactions, setTransactions] = useState<ITradeTransaction[]>([])

    useEffect(() => {
        getTransactions()
    }, [])


    function getTransactions() {
        if (transactions.length > 0) return;
        // get all transactions
        axios.get(`${backend_url}/user/transactions/${user?.id}`).then(res => {
            if (res.data.success) {
                setTransactions(res.data.data);
                console.log(res.data.data);
            } else {
                toast.error("Error occured while fetching transactions")
            }
        }).catch(err => {
            console.log(err)
            toast.error("Error occured while fetching transactions");
        })

    }

    return (
        <div>
            <Row>
                <section className="toolbar p-6 flex text-2xl gap-x-5 ml-8">
                    <Link to="/trade" className='text-gray-700 hover:text-black font-semibold underline-offset-4'>Buy/Sell</Link>
                    <Link to="trade/transactions" className='text-black font-semibold underline underline-offset-4'>Transactions</Link>
                </section>
                <section className='grid lg:grid-cols-2 gap-x-5'>
                    <aside className="p-3 shadow rounded-lg">
                        <div className="my-3 flex justify-between items-center font-medium text-xl">
                            Pending Transactions
                            <div className='flex items-center gap-x-2'>
                                <Dropdown style={{ background: "transparent" }}>
                                    <Dropdown.Toggle style={{ display: "flex", alignItems: "center", background: "transparent", outline: "none", border: "none", color: "black" }} >
                                        <FiFilter className='' />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu style={{ border: "1px solid var(--ion-color-success)", fontSize: "13px", color: "gray" }}>
                                        <Dropdown.Item href="/action-1" style={{ padding: "3px 10px" }}> <div className="border-b border-primaryGreen">KWH high - low</div>  </Dropdown.Item>
                                        <Dropdown.Item href="/action-2" style={{ padding: "3px 10px" }}><div className="border-b border-primaryGreen">KWH low - high</div></Dropdown.Item>
                                        <Dropdown.Item href="/action-3" style={{ padding: "3px 10px" }} >Something else</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>

                            </div>
                        </div>
                        <div className='border-b pb-2'>
                            <span className="text-gray-500 text-sm ">Transactions pending completion </span>
                        </div>
                        <section className='mt-4'>
                            {
                                transactions.map((item, index) => {
                                    if (item.status !== "pending" && item.status !== "open") return;
                                    return (
                                        <TransactionCard onUpdate={getTransactions} key={index} item={item} />
                                    )
                                })
                            }
                        </section>
                    </aside>
                    <aside className="p-3 shadow rounded-lg">
                        <div className="my-3 flex justify-between items-center font-medium text-xl">
                            Completed transactions
                            <div className='flex items-center gap-x-2'>
                                <Dropdown style={{ background: "transparent" }}>
                                    <Dropdown.Toggle style={{ display: "flex", alignItems: "center", background: "transparent", outline: "none", border: "none", color: "black" }} >
                                        <FiFilter className='' />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu style={{ border: "1px solid var(--ion-color-success)", fontSize: "13px", color: "gray" }}>
                                        <Dropdown.Item href="/action-1" style={{ padding: "3px 10px" }}> <div className="border-b border-primaryGreen">KWH high - low</div>  </Dropdown.Item>
                                        <Dropdown.Item href="/action-2" style={{ padding: "3px 10px" }}><div className="border-b border-primaryGreen">KWH low - high</div></Dropdown.Item>
                                        <Dropdown.Item href="/action-3" style={{ padding: "3px 10px" }} >Something else</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>

                            </div>
                        </div>
                        <div className='border-b pb-2'>
                            <span className="text-gray-500 text-sm ">Transactions processed on the blockchain </span>
                        </div>
                        <section className='mt-4'>
                            {
                                transactions.map((item, index) => {
                                    if (item.status !== "completed") return;
                                    return (
                                        <TransactionCard key={index} item={item} />
                                    )
                                })
                            }
                        </section>
                    </aside>
                </section>
            </Row>
        </div>
    )
}

export default TradeTransactions