import React, { useState } from 'react'
import { HiOutlineLightningBolt } from 'react-icons/hi'
import { IoTrashOutline } from 'react-icons/io5'
import { PiDatabase } from 'react-icons/pi'
import useEGYVault from '../../hooks/useEGYVault'
import ConfirmModal from '../ConfirmModal'
import { toast } from 'react-toastify'
import { motion, useIsPresent } from 'framer-motion'

const ListingsItem: React.FC<{ item: any }> = ({ item }) => {
    const { cancelListing } = useEGYVault()
    const [shouldCancel, setShouldCancel] = useState(false);
    const [loading, setLoading] = useState(false)

    const handleCancelListing = async () => {
        setLoading(true)
        const res = await cancelListing(item.index)
        if (res) {
            toast.success("Operation successfully")

        } else {
            toast.error("Failed to cancel")
        }
        setLoading(false)
    }



    return (
        <div className={`border-[1px] mb-3 flex justify-between rounded-md border-gray-400 p-2 cursor-pointer ${loading && " opacity-50"}`}>
            <div className="flex gap-x-1 items-center">
                <HiOutlineLightningBolt className='text-primaryGreen' /> <span className='text-sm font-medium'>{item?.energy} KWh</span>
            </div>
            <div className="flex gap-x-1 items-center">
                <PiDatabase className='text-primaryGreen' /> <span className='text-sm font-medium'>{item?.price.toFixed(3)} TRG</span>
            </div>
            <div className="" onClick={() => setShouldCancel(true)}>
                <button disabled={loading}>
                    <IoTrashOutline className='text-black text-lg' />
                </button>
            </div>
            <ConfirmModal message={"Are you sure you want to cancel this listing?"} title={"Cancel Listing"} show={shouldCancel} handleClose={(result) => { setShouldCancel(false); result && handleCancelListing() }} />
        </div>
    )
}

export default ListingsItem